
import companiesCamerasTableHeader from "@/components/companies/companiesCamerasTableHeader"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { decamelize } from "humps"

export default {
  components: {
    CopyTableToClipboard,
  },
  props: {
    companyId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      items: [],
      headers: [],
      total: 0,
      loading: false,
      tableHeight: 0,
      options: {},
      sortBy: "name",
      sortDesc: false,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.companiesCamerasTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchCameras()
      },
      deep: true,
    },
  },
  async mounted() {
    this.headers = companiesCamerasTableHeader(this)
  },
  methods: {
    async fetchCameras() {
      if (!this.companyId) {
        return
      }
      try {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage: limit } = this.options
        const sort = `${decamelize(sortBy?.[0] || "")}|${this.whichSort(
          sortDesc?.[0]
        )}`
        const params = { sort, page, limit }

        const { total, items } = await EvercamApi.companies.getCompanyCameras(
          this.companyId,
          { params }
        )
        this.total = total
        this.items = items
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.company.fetch_cameras_failed"),
          error,
        })
      } finally {
        this.loading = false
      }
    },
    onResize() {
      this.tableHeight = null
      let headerAndFooterHeight =
          this.$refs.headerContainer?.getBoundingClientRect()?.height,
        top = this.$refs.tableContainer?.$el?.getBoundingClientRect()?.top
      headerAndFooterHeight += 35
      this.tableHeight = window.innerHeight - (headerAndFooterHeight + top)
    },
    whichSort(type) {
      return type ? "desc" : "asc"
    },
  },
}
