
import companiesProjectsTableHeader from "@/components/companies/companiesProjectsTableHeaders"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { decamelize } from "humps"

export default {
  components: {
    CopyTableToClipboard,
  },
  props: {
    companyId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: [],
      options: {},
      total: 0,
      loading: false,
      sortBy: "name",
      sortDesc: true,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.projectsTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchProjects()
      },
      deep: true,
    },
  },
  async mounted() {
    this.headers = companiesProjectsTableHeader(this)
  },
  methods: {
    async fetchProjects() {
      if (!this.companyId) {
        return
      }
      try {
        this.loading = true
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        const params = {
          sort: `${decamelize(sortBy[0])}|${this.whichSort(sortDesc[0])}`,
          page: page,
          limit: itemsPerPage,
        }
        const response = await EvercamApi.companies.getCompanyProjects(
          this.companyId,
          {
            params,
          }
        )
        this.total = response?.total || 0
        this.items = response?.items || []
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.company.fetch_projects_failed"),
          error,
        })
      } finally {
        this.loading = false
      }
    },
    whichSort(type) {
      return type ? "desc" : "asc"
    },
    formatDate(date, format = "DD MMM YYYY HH:mm:ss") {
      return this.$moment(date).isValid()
        ? this.$moment(date).format(format)
        : "-"
    },
  },
}
