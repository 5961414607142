
import VueHighcharts from "@evercam/shared/components/Highcharts"
const colors = [
  "#ff80ff",
  "#ff8c1a",
  "#ffff66",
  "#80ff80",
  "#ffe6ff",
  "#ffec99",
  "#6666ff",
  "#0000ff",
  "#1aff1a",
  "#9999ff",
  "#ffe6e6",
  "#e6ffff",
  "#66ff66",
  "#4dffff",
  "#ff9999",
  "#ffcc66",
  "#4dff4d",
  "#1a1aff",
  "#e6e6ff",
  "#33ffff",
  "#00ffff",
  "#ffd980",
  "#99ff99",
  "#ffb3b3",
  "#ff1aff",
  "#ffff33",
  "#99ffff",
  "#e6ffe6",
  "#80ffff",
  "#ffcccc",
  "#b3ffff",
  "#ffff00",
  "#ffffcc",
  "#ffffb3",
  "#ccffff",
  "#3333ff",
  "#ff3333",
  "#ff00ff",
  "#33ff33",
  "#ff99ff",
  "#ccffcc",
  "#ff0000",
  "#ff4d4d",
  "#ffb84d",
  "#66ffff",
  "#fffccc",
  "#ffb3ff",
  "#ffffe6",
  "#ff6666",
  "#ffff99",
  "#b3ffb3",
  "#ff66ff",
  "#ffa033",
  "#b3b3ff",
  "#ff7f00",
  "#ff33ff",
  "#ccccff",
  "#4d4dff",
  "#ff1a1a",
  "#8080ff",
  "#fffde6",
  "#1affff",
  "#ff8080",
  "#ffccff",
  "#00ff00",
  "#ff4dff",
]
export default {
  name: "PaChart",
  components: {
    highcharts: VueHighcharts,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    initialChartOptions: {
      type: Object,
      default: () => {},
    },
    hideAllSeries: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartKey: 0,
      dataAvailable: false,
      chartOptions: { ...{ colors }, ...this.initialChartOptions },
    }
  },
  watch: {
    chartData() {
      this.chartKey++
      this.initChart()
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    chartCallback(chart) {
      if (this.chartData.highchartsLabels.length === 0) {
        this.chartOptions.title.text = "No data available"

        return
      }
      this.chartOptions.title.text = ""
      if (this.hideAllSeries && chart.series.length > 1) {
        this.chartOptions.series.unshift({
          name: "Hide All",
          data: [],
          visible: false,
        })
        this.chartOptions.plotOptions = {
          column: {
            ...this.chartOptions.plotOptions.column,
            events: {
              legendItemClick: function (col) {
                if (col.target.index === 0) {
                  chart.series.forEach((serie) => {
                    if (serie.index !== 0) {
                      this.visible
                        ? serie.setVisible(true, false)
                        : serie.setVisible(false, false)
                    }
                  })
                  this.visible
                    ? col.target.update({ name: "Hide All" })
                    : col.target.update({ name: "Show All" })
                }
              },
            },
          },
        }
      }
    },
    initChart() {
      this.dataAvailable = false
      let chartData = this.chartData
      this.chartOptions.xAxis.categories = chartData.highchartsLabels
      this.chartOptions.series = chartData.datasets.map((dataset) => {
        if (this.hideAllSeries)
          dataset.label = `${dataset.label} (${dataset.data.reduce(
            (a, b) => a + b,
            0
          )})`

        return {
          name: dataset.label.replace(/_/g, " "),
          data: dataset.data.reverse(),
          color: dataset.borderColor,
        }
      })

      this.dataAvailable = true
    },
  },
}
