
import PaEventsStatsTable from "@/components/pa/PaEventsStatsTable"
import PaChart from "@evercam/shared/components/PaChart"
import CompanyProjectSelect from "@evercam/shared/components/CompanyProjectSelect"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { events } from "@/components/pa/events"

export default {
  components: {
    PaEventsStatsTable,
    PaChart,
    CompanyProjectSelect,
  },
  props: {
    companyId: {
      type: [String, Number],
      required: true,
    },
    eventsOf: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filterBy: "month",
      periods: [
        {
          value: "day",
          text: "Daily",
        },
        {
          value: "week",
          text: "Weekly",
        },
        {
          value: "month",
          text: "Monthly",
        },
      ],
      loaded: false,
      rows: [],
      selectedProjectEventExid: "",
      labels: [],
      selectedDatasets: [],
      datasets: [],
      items: [],
      headers: [],
      eventsName: events,
      loading: true,
      chartOptions: {
        chart: {
          type: "column",
          zoomType: "xy",
        },
        legend: {
          align: "center",
          verticalAlign: "top",
        },
        title: {
          text: "",
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: {
          title: {
            text: "",
          },
          minorTickInterval: 25,
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
      },
      startDatePicker: false,
      startDate: this.$moment(new Date())
        .subtract(90, "d")
        .format("YYYY-MM-DD"),
      endDatePicker: false,
      endDate: this.$moment(new Date()).format("YYYY-MM-DD"),
    }
  },
  computed: {
    chartData() {
      return {
        datasets: this.selectedDatasets,
        highchartsLabels: this.labels,
      }
    },
    reportTitle() {
      let title = ""
      if (this.filterBy === "month") {
        title = "Monthly Events"
      } else if (this.filterBy === "week") {
        title = "Weekly Events"
      } else if (this.filterBy === "day") {
        title = "Daily Events"
      }

      return title
    },
  },
  async mounted() {
    await this.fetchEventsStats()
    this.loaded = true
  },
  methods: {
    async fetchEventsStats() {
      try {
        this.headers = []
        this.items = []
        this.selectedDatasets = []
        this.labels = []

        this.loading = true
        let response
        let params = {
          period: this.filterBy,
          startDate: this.startDate,
          endDate: this.endDate,
        }
        if (this.eventsOf === "companies" && !this.selectedProjectEventExid) {
          response = await EvercamApi.analytics.getPACompanyPeriodEvents(
            this.companyId,
            params
          )
        } else if (
          this.eventsOf === "projects" ||
          this.selectedProjectEventExid
        ) {
          response = await EvercamApi.analytics.getPAProjectPeriodEvents(
            this.selectedProjectEventExid,
            params
          )
        }

        this.total = response.count

        const { headers, rows, days } = response
          .sort((a, b) => {
            return a.day > b.day ? 1 : -1
          })
          .reduce(
            (acc, item) => {
              const day = this.$moment(item.period).format("DD MMM YYYY")
              const event = item.name
              const count = Number.parseInt(item.count)
              let { headers, rows, days } = acc

              if (!acc.days.includes(day)) {
                days = [...days, day]
                headers = [
                  ...headers,
                  {
                    value: day,
                    text: this.$moment(item.period).format("DD/MM/YYYY"),
                    visible: true,
                    width: 110,
                  },
                ]
              }

              rows = {
                ...acc.rows,
                [event]: {
                  ...acc.rows[event],
                  name: event,
                  [day]: count,
                  total: count + (acc.rows[event]?.total || 0),
                },
              }

              return {
                headers,
                rows,
                days,
              }
            },
            { days: [], headers: [], rows: this.eventsName }
          )

        this.headers = [
          {
            text: "Event",
            value: "name",
          },
          {
            text: "Total",
            value: "total",
          },
          ...headers,
        ]

        this.rows = Object.values(rows)

        const emptyChartsDatasets = events.reduce((acc, e) => {
          if (!rows[e.name]) {
            return acc
          }

          return [
            ...acc,
            {
              label: e.name,
              data: [],
              borderColor: e.color,
            },
          ]
        }, [])

        const chartDatasets = days.reduce((acc, day) => {
          return acc.map((dataset) => {
            const eventDays = rows[dataset.label] || {}

            return {
              ...dataset,
              data: [...dataset.data, eventDays[day] || 0],
            }
          })
        }, emptyChartsDatasets)

        this.datasets = chartDatasets
        this.selectedDatasets = chartDatasets
        // flip the data arrays in each dataset in this.selectedDatasets
        this.selectedDatasets.forEach((dataset) => {
          dataset.data.reverse()
        })
        this.labels = days
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: "events stats",
          }),
          error,
        })
      } finally {
        this.loading = false
      }
    },
    fetchProjectEvents(value) {
      this.selectedProjectEventExid = value
      this.fetchEventsStats()
    },
    filterByPeriod() {
      this.endDate = this.$moment(new Date()).format("YYYY-MM-DD")
      if (this.filterBy === "month") {
        this.startDate = this.$moment(new Date())
          .subtract(90, "d")
          .format("YYYY-MM-DD")
      } else if (this.filterBy === "week") {
        this.startDate = this.$moment(new Date())
          .subtract(49, "d")
          .format("YYYY-MM-DD")
      } else if (this.filterBy === "day") {
        this.startDate = this.$moment(new Date())
          .subtract(7, "d")
          .format("YYYY-MM-DD")
      }
      this.fetchEventsStats()
    },
    changeDateRange() {
      this.startDatePicker = false
      this.endDatePicker = false
      this.fetchEventsStats()
    },
  },
}
