
// import CompaniesUsersTable from "@/components/companies/CompaniesUsersTable"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import PaChart from "@evercam/shared/components/PaChart"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: {
    // CompaniesUsersTable,
    EvercamLoadingAnimation,
    PaChart,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    companyExid: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      datasets: [],
      labels: [],
      displayDateRange: "",
      dateRange: [],
      from: this.$moment().subtract("12", "months").format("YYYY-MM-DD"),
      to: this.formatDate(new Date(), "YYYY-MM-DD"),
      initialChartOptions: {
        chart: {
          type: "line",
          zoomType: "xy",
        },
        title: {
          text: this.$t("content.pa.title"),
          align: "left",
          margin: 50,
        },

        subtitle: {
          text: this.companyName,
          align: "left",
        },

        xAxis: {
          crosshair: true,
          tickInterval: 25,
        },

        yAxis: {
          title: {
            text: "Number of active users",
          },
        },

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
          line: {
            linecap: "square",
            lineWidth: 3,
            marker: {
              enabled: false,
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      },
    }
  },
  watch: {
    dateRange(value) {
      if (value.length !== 2) {
        return
      }
      const [startDate, endDate] = this.$moment(value[0]).isAfter(
        this.$moment(value[1])
      )
        ? value.reverse()
        : value
      this.displayDateRange = `${startDate} / ${endDate}`
      this.from = `${startDate}-01`
      this.to = `${endDate}-${this.$moment(endDate, "YYYY-MM").daysInMonth()}`
      this.fetchActiveUsers()
    },
  },
  async mounted() {
    this.dateRange = [
      this.formatDate(this.from, "YYYY-MM"),
      this.formatDate(this.to, "YYYY-MM"),
    ]
  },
  methods: {
    async fetchActiveUsers() {
      try {
        this.loading = true
        this.datasets = []
        this.labels = []
        let params = {
          from: this.from,
          to: this.to,
          companyId: this.companyExid,
        }

        let response = await EvercamApi.companies.getActiveUsers(params)

        let dau = []
        let wau = []
        let mau = []

        response?.forEach((row) => {
          this.labels = [
            this.$moment(row.date).format("MMM Do YYYY"),
            ...this.labels,
          ]
          dau = [row.dau, ...dau]
          wau = [row.wau, ...wau]
          mau = [row.mau, ...mau]
        })

        // reverse the labels
        this.labels = this.labels.reverse()

        this.datasets = [
          ...this.datasets,
          {
            label: "Monthly active users",
            data: mau,
            borderColor: this.$vuetify.theme.currentTheme.primary,
          },
          {
            label: "Weekly active users",
            data: wau,
            borderColor: this.$vuetify.theme.currentTheme.error,
          },
          {
            label: "Daily active users",
            data: dau,
            borderColor: this.$vuetify.theme.currentTheme.success,
          },
        ]
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: "active users",
          }),
          error,
        })
      } finally {
        this.loading = false
      }
    },
    formatDate(date, format = "DD MMM YYYY HH:mm:ss") {
      return this.$moment(date).isValid()
        ? this.$moment(date).format(format)
        : "-"
    },
  },
}
