
import PaEventsStats from "@/components/pa/PaEventsStats"
import PaActiveUsers from "@/components/pa/PaActiveUsers"
import CompaniesDetails from "@/components/companies/CompaniesDetails"
import CompaniesProjectsTable from "@/components/companies/CompaniesProjectsTable"
import CompaniesCamerasTable from "@/components/companies/CompaniesCamerasTable"
import LogoDisplayer from "@evercam/shared/components/LogoDisplayer"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  name: "Company",
  components: {
    PaEventsStats,
    PaActiveUsers,
    CompaniesDetails,
    CompaniesProjectsTable,
    LogoDisplayer,
    CompaniesCamerasTable,
  },
  data() {
    return {
      currentTab: 0,
      companyDetails: null,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  async mounted() {
    let response
    try {
      response = await EvercamApi.companies.getPACompanyDetails(
        this.accountStore.companyId
      )
    } catch (error) {
      this.$notifications.error({
        text: this.$t("content.companyfetch_company_details_failed"),
        error,
      })
    }

    const companyDetails = response

    this.companyDetails = companyDetails || {}
  },
}
