
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"

export default {
  name: "CopyTableToClipboard",
  components: {
    CopyToClipboardBtn,
  },
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    headersObject() {
      return this.headers.reduce((acc, header) => {
        return {
          ...acc,
          [header.value]: header,
        }
      }, {})
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.copyable !== false)
    },
  },
  methods: {
    defaultToStringFn(item = {}, key = "") {
      if (item[key] === null || item[key] === undefined) {
        return ""
      }

      return `${item[key]}`.replace(/[\n\t]+/g, " ")
    },
    jsonToText() {
      if (this.items.length === 0) {
        return ""
      }

      const [headersValues, headerTexts] = this.filteredHeaders.reduce(
        ([values, texts], header) => [
          [...values, header.value],
          [...texts, header.text],
        ],
        [[], []]
      )

      return this.items.reduce((acc, item) => {
        return (
          acc +
          "\n" +
          headersValues
            .reduce((acc, key) => {
              const toString =
                this.headersObject[key].toStringFn || this.defaultToStringFn

              return [...acc, toString(item, key)]
            }, [])
            .join("\t")
        )
      }, headerTexts.join("\t") + "\n")
    },
  },
}
