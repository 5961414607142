export default (self) => [
  {
    value: "name",
    text: "Camera Name",
    visible: true,
    width: 190,
    sortable: true,
  },
  {
    value: "exid",
    text: "Camera ID",
    visible: true,
    width: 150,
    sortable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 150,
    visible: true,
    sortable: true,
    toStringFn: (item, key) => self.$t(`content.camera_statuses.${item[key]}`),
  },
  {
    value: "projectExid",
    text: "Project ID",
    width: 200,
    visible: true,
    sortable: true,
  },
  {
    value: "usersCount",
    text: "# Users",
    width: 20,
    visible: true,
    sortable: true,
  },
]
