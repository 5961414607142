
export default {
  props: {
    companyDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    details() {
      return [
        { label: "# Projects", value: this.companyDetails.projects },
        { label: "# Cameras", value: this.companyDetails.cameras },
        { label: "# Sessions", value: this.companyDetails.sessions },
        {
          label: "# Active Users",
          value: this.companyDetails.activeUsers,
        },
      ]
    },
    activeUsersPercentage() {
      return (100 * this.companyDetails.activeUsers) / this.companyDetails.users
    },
  },
}
